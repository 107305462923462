.skill{
    margin-left: 8%;
}
.skilll{
    margin-left: 2px;
}
.pedagogi_skills{
    box-shadow: 10px 8px 4px rgba(0, 0, 0, 0.25);
}
.box-right{
    margin-left: 2rem;
}
@media(max-width: 640px){
    .skill{
        margin-left: 1%;
    }
    .skilll{
        margin-left: 1%;
    }
    .pedagogi_skills{
        box-shadow:none;
    }
}