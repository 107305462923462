.navbarwrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navbarwrapper h6 {
    font-size: 32px;
    color: rgb(30 154 214 / 1) !important;
    font-weight: bold;
    padding: 20px;
}

.navbarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgb(30 154 214 / 1) !important;
    height: 50px;
}

@media screen and (max-width: 768px) {
    .navbarwrapper h6 {
        font-size: 16px !important;
        color: white !important;
        padding-left: 0px;
    }
    .navbarContainer{
        height: 70px;
        position: fixed;
        top: 0;
        z-index: 10;
    }
}