.home-modal .dilogPanel {
    background-color: transparent !important;
    padding: 0px !important;
    margin-top: 8rem !important;
}

@media screen and (min-width:640px)  {
    .home-modal .dilogPanel{
        max-width: 31rem !important;
    }

}

.home-modal .dilogPanel .modalApplyBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
.home-modal .dilogPanel .modalApplyBtn button {
    width: 30%;
    background-color: #0076BD;
    color: white;
    border-radius: 5px;
    padding:10px;
    cursor: pointer;
    outline: none;
}

.home-modal .modal-svg {
    cursor: pointer;
    fill: white;
}


.home-modal .home-modal-Image-slider {
    border-radius: 10px !important;
    overflow: hidden;
}

.home-modal .home-modal-Image-slider img {
    border-radius: 10px !important;
}

.home-modal img {
    width: 100%;
}
.home-modal video {
    height: 500px !important;
    width: 500px !important;
    border-radius: 10px;
}

.home-modal .slick-prev {
    left: -55px !important;
    top: 45% !important;
}

.home-modal .slick-next {
    right: -26px !important;
    top: 45% !important;
}

.modal-backdrop.show {
    opacity: 0 !important;
}

/* responsiveness */

@media screen and (max-width: 768px) {
    /* .slick-prev {
        left: 0px !important;
        top: 106% !important;
    }

    .slick-next {
        left: 80px !important;
        top: 106% !important;
    } */

    .home-modal video {
        height: auto !important;
    }
    .home-modal .dilogPanel .modalApplyBtn{
        flex-direction: column;
    }
   
    .home-modal .dilogPanel .modalApplyBtn button {
        width: 60%;

    }
}