@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body {
  overflow-x: hidden;
  max-width: 100%;
}

.space-outside-round {
  shape-outside: inset(1% round 50%);
  border-radius: 50%;
  float: left;
}

.slick-dots li button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #c4c4c4;
  font-size: 0;
}

.slick-dots li button:before {
  font-size: 0;
}

.slick-dots li.slick-active button {
  font-size: 0;
  /* background-color: #1e9ad6; */
}

.slick-prev {
  left: 10px !important;
  z-index: 2;
}

.slick-next {
  right: 35px !important;
  z-index: 2;
}

.slick-next:before,
.slick-prev:before {
  color: #c4c4c4;
  font-size: 3rem;
  opacity: 1;
  transition: 200ms;
}

@media only screen and (max-width: 600px) {
  
  .slick-prev {
    left: 4px !important;
    z-index: 2;
  }

  .slick-next {
    right: 17px !important;
    z-index: 2;
  }
  

  .slick-next:before,
  .slick-prev:before {
    color: #c4c4c4;
    font-size: 2rem;
    opacity: 1;
    transition: 200ms;
  }
  .journeySlide .slick-slider .slick-prev {
    left: 1px !important;
    z-index: 2;
  }

  .journeySlide .slick-slider .slick-next {
    right: 50px !important;
    z-index: 2 !important;
  }
}

.slick-next:hover:before,
.slick-prev:hover:before {
  color: #8a8a8a;
}

.title-textShadow {
  text-shadow: 0px 0 rgb(201, 201, 201), -1px 0px 0 rgb(153, 153, 153),
    -2px 0px 0 rgb(106, 106, 106), -3px 0px 0 rgb(58, 58, 58),
    -4px 0px 0 rgb(11, 11, 11), -5px 0px 4px rgba(0, 0, 0, 0.21),
    -5px 0px 1px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.values-row {
  width: 100%;
  flex-wrap: wrap;
}

.values-row:first-child {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.values-title {
  font-weight: 600;
  opacity: 0.5;
  margin-top: 1rem;
  max-width: 150px;
}

.icons-cont {
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 209px;
}

img.values-icon {
  width: 65px;
  height: 65px;
}

.marquee-container {
  height: 50px;
  background-color: #0076BD;
}

.marquee-container .child p {
  margin-bottom: 0px;
  font-size: 24px;
  color: white;
  font-weight: 700
}

.bgimageHomepage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("./images/Cover\ Pic.jpg");
  display: flex;
  align-items: flex-end;
}
.footerbg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("./images/Bottom page.png");
  display: flex;
  align-items: center;
}

.contntLink a {
  color: #0076BD !important;
  text-decoration: none !important;
}


@media (max-width: 640px) {
  .icons-cont {
    min-width: 261px;
    margin-bottom: 2rem;
    padding-right: 0rem !important;
  }
}

.bgimageHomepage .slick-prev {
  left: 0px !important;
}

.bgimageHomepage .slick-next {
  right: 26px !important;
}


.blog {
  margin-top: 150px;
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.blog-post {
  background-color: #fff;
  border: 1px solid blue;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.blog-post-content {

  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more{
  color: blue !important;
}

.pdf-container {
  margin: 100px auto;
  width: 80%;
}

.pdf-table {
  border-collapse: collapse;
  width: 100%;
}

.pdf-table th,
.pdf-table td {
  padding: 10px;
  border: 1px solid #000;
  text-align: center;
}

.pdf-table th {
  font-weight: bold;
}
