.blog-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #22C8BA;
  }

  .blog-post {
    display: flex;
    background-color: white;
    border-radius: 8px;
    margin: 20px 0; /* Added margin between blog boxes */
    overflow: hidden;
    width: 80%;
    max-width: 800px;
    min-height: 200px; /* Ensure the minimum height is consistent */
    flex-direction: row;
  }
  
  .blog-image {
    width: 40%; /* Adjust width to ensure side-by-side layout */
    object-fit: cover;
  }
  
  .blog-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%; /* Adjust width to ensure side-by-side layout */
  }
  
  .blog-date {
    color: gray;
  }
  
  .blog-title {
    color: #002080;
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .blog-excerpt {
    color: #333;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit the text to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (max-width: 768px) {
    .blog-post {
      flex-direction: column;
      width: 100%;
    }
  
    .blog-image {
      width: 100%;
      height: auto; /* Ensure image scales correctly */
    }
  
    .blog-content {
      width: 100%;
    }
    .blog-title{
        font-size: 1em;
    }
    .blog-excerpt{
        font-size: 0.7rem;
    }

  }